@use "./fonts" as *;
@use "./colors" as *;

html {
  box-sizing: border-box;
  margin: 0;

  > * {
    box-sizing: inherit;
  }
}

body {
  background: #111;
  min-height: 100vh;
  min-width: 100vw;
  background-image: linear-gradient(45deg, #111, #121212);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  color: #eeee;
  font-family: "Work Sans", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 300;
  line-height: 1.5;
  max-width: 100vw;
  overflow: auto;
  padding: 0;
}

h1 {
  font-family: "Ubuntu Mono";
  text-align: center;
  font-size: 2.5rem;
  margin-top: 4rem;
  margin-bottom: 0;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  max-width: 900px;
  text-align: center;
  align-items: center;
  max-width: 100%;

  img {
    object-fit: contain;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;

    li {
      font-weight: 700;
      margin: 1rem;
    }
  }
}

a {
  color: inherit;
  &:visited {
    color: inherit;
  }

  &:hover {
    background-color: $green;
    color: #222;
  }
}
